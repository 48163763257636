import React from "react";
import "./customInputGroup.css";
import { Input } from "./base";
const InputGroupPrepend = ({ children }) => {
  return <div className="custom-input-group-prepend">{children}</div>;
};

const InputGroupAppend = ({ children }) => {
  return <div className="custom-input-group-append">{children}</div>;
};

const InputGroupText = ({ children }) => {
  return <span className="custom-input-group-text">{children}</span>;
};

const InputGroup = ({
  prepend,
  append,
  labelText,
  labelHtmlFor,
  inputWidth,
  isError,
  containerClass = "",
  ...restProps
}) => {
  const showPrepend = !!prepend;
  const showAppend = !!append;
  const inputClass = showPrepend
    ? "custom-input-prepend"
    : "custom-input-append";
  return (
    <div className={`custom-input-group ${containerClass}`}>
      {showPrepend ? (
        <InputGroupPrepend>
          <InputGroupText>{prepend}</InputGroupText>
        </InputGroupPrepend>
      ) : null}

      <Input
        type="text"
        style={{ width: inputWidth }}
        className={`input custom-input ${inputClass} ${isError &&
          "input--error"}`}
        {...restProps}
      />
      {showAppend ? (
        <InputGroupAppend>
          <InputGroupText>{append}</InputGroupText>
        </InputGroupAppend>
      ) : null}
    </div>
  );
};

export default InputGroup;
