import React from "react";
import "./spinner.css";
import cs from "classnames";

export default function Spinner({ className, ...rest }) {
  return (
    <svg
      className={cs("Spinner", className)}
      x="0px"
      y="0px"
      viewBox="0 0 150 150"
      {...rest}
    >
      <circle className="Spinner--inner" cx="75" cy="75" r="60" />
    </svg>
  );
}
