import React, { lazy, Suspense } from "react";
import Layout from "../components/layout";
import PageLoading from "../components/loading";
import { isEnabled, Feature } from "../common/features";
const AppWithCaseManagement = lazy(() =>
  import("../components/app-with-case-management")
);

const AppWithoutCaseManagement = lazy(() =>
  import("../components/app-without-case-management")
);

export default function App() {
  if (typeof window === "undefined") {
    return null;
  }
  const AppAsync = isEnabled(Feature.AUTH_AND_CASE_MANAGEMENT)
    ? AppWithCaseManagement
    : AppWithoutCaseManagement;
  return (
    <Suspense
      fallback={
        <Layout>
          <PageLoading title="Loading" />
        </Layout>
      }
    >
      <AppAsync />
    </Suspense>
  );
}
