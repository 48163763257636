import React from "react";
import { Redirect } from "@reach/router";
import { withPrefix } from "gatsby";
import log from "../common/log";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    log.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to={withPrefix("/error")} noThrow />;
    }

    return this.props.children;
  }
}
